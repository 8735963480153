<template>
  <div class="csn-dropdown-arrow">
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 6 6 C 5.9 6 5.8 6 5.7 5.9 L 1 1 C 0 0 0 0 0 0 C 0.3 0 0.5 0 11 0 H 12 L 11 0 C 11 0 11 0 11 0 C 12 0 12 0 12 0 L 6.3 5.9 C 6.2 6 6.1 6 6 6 Z"
      />
    </svg>
  </div>
</template>

<script>
import { DROPDOWN_ARROW } from '@/constants'

export default {
  name: DROPDOWN_ARROW,
}
</script>
